import { Link } from 'react-router-dom';
import { CONTENTSNUM } from '../../constant';
import List from './list';

export default function MiniBoard({
  title,
  addLink,
  plusLink,
  items = [],
  url,
  type,
}) {
  return (
    <div className='w-full max-w-[500px] h-[220px] bg-gray-50 p-4 flex shadow-md flex-col items-center'>
      <div className='w-full mb-5 flex justify-center text-2xl relative'>
        <span className='w-full text-center'>{title}</span>
        <div className='flex flex-col absolute right-0'>
          {addLink && (
            <Link
              to={addLink}
              className='hover:no-underline text-sm text-slate-500'
            >
              + 추가하기
            </Link>
          )}
          {plusLink && (
            <Link
              to={plusLink}
              state={{ data: items, title }}
              className='hover:no-underline text-sm text-slate-500'
            >
              + 더보기
            </Link>
          )}
        </div>
      </div>
      <ul className='m-0 w-full flex flex-col items-center justify-center'>
        {items &&
          items.map((item, i) => {
            if (i < CONTENTSNUM) {
              return List({
                title: `${item?.title || item.company}`,
                id: `${item?.id}`,
                url: `${url}${item.id}`,
                data: item,
                type,
              });
            }
          })}
      </ul>
    </div>
  );
}
