import Layout from '../../containers/layoutCotainer';
import GreetingImageWebp from '../../assets/img/greetingImage.webp';
import GreetingImageJpg from '../../assets/img/greetingImage.jpg';

export default function GreetingPage() {
  return (
    <Layout title='대표이사 인사말'>
      {/* <div className='w-full space-x-4 h-[350px] overflow-clip'>
        <picture>
          <source
            srcset={ManImageWebp}
            className='w-full h-full'
            type='image/webp'
          />
          <img
            src={MainImageJpeg}
            alt='mainImage'
            className='w-full relative min-h-[500px]'
            fetchpriority='high'
          />
        </picture>
      </div> */}
      {/* <div className='mx-auto w-[1600px] flex px-40 space-x-12 my-16 '>
        <div className='w-[720px] '>
          <picture className='shadow-lg'>
            <source
              srcset={CheifImageWebp}
              className='w-full h-full'
              type='image/webp'
            />
            <img src={CheifImageJpg} alt='mainImage' fetchpriority='high' />
          </picture>
        </div>
        <div className='font-[NanoSan] w-[2400px] space-y-10 text-lg font-light text-gray-600'>
          <p>안녕하십니까? 장치승입니다.</p>
          <p>
            우리 재활용 가족 여러분과 호흡을 같이 하면서 여러분의 노고와
            어려움을 공유하고 해소하고자 했던 시간도 어언 10여년이 흘렀습니다.{' '}
          </p>

          <p>
            저는 2020년 12월 한국순환자원유통지원센터를 정년퇴직하고 새로운 법인
            설립과 정부 R&D사업의 주관책임자로 활동하는 등 예전과는 다른
            측면에서 우리의 재활용분야에 참여 해 왔습니다.
          </p>
          <p>
            오랜 공직생활과 유통지원센터 설립, EPR제도 운용과정에서 만난 많은
            분들의 의견을 청취하고 나름 문제를 개선코자 노력하였으나 생각만큼
            성과를 이루지 못하여 항상 자책하며 소기의 성과를 낼 수 있는 방법을
            모색하다 생각만 해서는 아무것도 되는 것이 없고 작으나마 행동해야
            만이 이룰 수 있다는 진리에 따라 금번에 여러분 곁에 다시 서게 된
            것입니다.
          </p>
          <p>
            저는 외부 충격에 취약한 시장구조, 협소한 거래선, 거래가격 결정의
            모호성, 획일적 낮은 품질과 재활용용도의 단순화 및 수요시장 협소,
            신뢰도 낮은 시장거래를 어떻게 개선하여 우리나라 재활용 산업이
            세계적으로 선두에 설 수 있을까를 고민하였고 그 해법은 찾았으나 이를
            이행하기에는 많은 비용이 소요되어 지금껏 행동하지 못했습니다.
          </p>
          <p>
            이제 현재의 격에 맞는 시스템을 시작으로 우리 재활용 가족이 즐겁게
            사업활동 하실 수 있는 분위기를 조성하고 거듭하는 시스템 개선과
            재활용 산업에 유리한 우리나라의 지리적 여건, 오늘날 우리
            재활용시장을 만들어 낸 재활용 가족의 열성을 함께하여 우리나라
            재활용시장이 세계시장의 중심이 되는 “시작은 미약하나 그 끝은
            창대하리라”는 말씀이 우리 재활용시장에서 이루어질 수 있도록
            하겠습니다.
          </p>
          <p>
            제가 여러분 사업장의 영업사원이자 사업의 카운슬러 역할을 성실히 수행
            할 것이니 많은 분들이 함께 해 주시기 바라며 항상 재활용과 관련된
            어떤 사항도 서로 허심탄회하게 논의하고 발전해 가는 마당이 되기를
            기원합니다.
          </p>
          <p>감사합니다.</p>
          <p className='w-full text-right font-semibold'>
            {' '}
            대표이사 장치승올림
          </p>
        </div>
      </div> */}
      <div className='w-[90%]'>
        <picture className='w-ful h-fit'>
          <source srcSet={GreetingImageWebp} type='image/webp' />
          <img src={GreetingImageJpg} alt='greetingImage' />
        </picture>
      </div>
    </Layout>
  );
}
