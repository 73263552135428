import axios from 'axios';
import { BASEURL } from './constant';

const api = axios.create({
  baseURL: BASEURL,
  headers: { 'Content-Type': 'application/json' },
});

export const notice = {
  uploadImageToS3: async (data) => {
    try {
      return await api.put(data.url, data.file, {
        headers: { 'Content-Type': 'image/*' },
      });
    } catch (e) {
      console.error(e);
    }
  },
};
export const submitApi = {
  submit: async (data) => {
    try {
      const res = await api.post('api/record', data);
      if (res.data.success) {
        alert('제출 완료되었습니다.');
      } else {
        alert(`다시 시도 해주세요 `);
      }
    } catch (error) {
      alert(`다시 시도 해주세요 ${error}`);
    }
  },
};

export const signApi = {
  signIn: (data) => api.post('api/cms/login', data),
};
export const noticeApi = {
  getNotice: async () => {
    const res = await api.get('api/notice');
    if (res.data.result.length) {
      res.data.result.reverse();
    }
    return res.data.result;
  },
};
export const newsApi = {
  getAll: () => api.get('api/cms/news'),
};

export const pickApi = {
  getAll: () => api.get('api/cms/record?recordType=makeMaterials'),
};
export const produceApi = {
  getAll: () => api.get('api/cms/record?recordType=makeProduct'),
};
export const productApi = {
  getAll: () => api.get('api/cms/record?recordType=wholeSale'),
};
export const sfApi = {
  getAll: () => api.get('api/cms/record?recordType=smartFactory'),
};
export const etcApi = {
  getAll: () => api.get('api/cms/record?recordType=etc'),
};
export const esgApi = {
  getAll: () => api.get('api/cms/record?recordType=esg'),
};
