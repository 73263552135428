import { useForm } from 'react-hook-form';
import Layout from '../../../../containers/adminLayoutContainer';
import { adminApi } from '../../../../adminApi';
import { notice } from '../../../../api';
import { useNavigate } from 'react-router-dom';

export default function AddNews() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      //call preSignedURL
      const res = await adminApi.getUrl({ contentType: 'image/*' });
      //return preSignedURL
      //upload image to AWS preSignedURL
      await notice.uploadImageToS3({
        url: res.data.presignedUrl,
        file: data.file[0],
      });

      const result = await adminApi.addNotice({
        title: data.title,
        url: res.data.url,
        height: data.height,
        width: data.width,
      });
      if (result.status === 200) {
        navigate('/admin');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <form
        className='flex w-full lg:px-0 lg:w-[50%] my-auto flex-col justify-center items-center px-6 '
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='w-full grid gap-x-6 gap-y-8  grid-cols-1 lg:grid-cols-6'>
          <div className='lg:col-span-4'>
            <label
              htmlFor='title'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Title
            </label>
            <div className='mt-2'>
              <input
                id='title'
                {...register('title')}
                type='title'
                autoComplete='title'
                required
                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='lg:col-span-3'>
            <label
              htmlFor='height'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              높이(Height)
            </label>
            <div className='mt-2'>
              <input
                id='height'
                {...register('height')}
                type='height'
                autoComplete='height'
                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='lg:col-span-3'>
            <label
              htmlFor='width'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              넓이(Width)
            </label>
            <div className='mt-2'>
              <input
                id='width'
                {...register('width')}
                type='width'
                autoComplete='width'
                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='col-span-full'>
            <label
              htmlFor='file'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              팝업 내부 사진
            </label>
            <div className='mt-2 '>
              <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 '>
                <span className='flex select-none items-center pl-3 text-gray-500 sm:text-xs'>
                  사진
                </span>
                <input
                  type='file'
                  {...register('file')}
                  id='file'
                  className='block text-right px-2 focus:outline-none flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </div>
          <div className='flex justify-end col-span-full mt-6'>
            <button
              type='submit'
              className='flex w-[20%] min-w-fit justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600'
            >
              추가하기
            </button>
          </div>
          {/* <ErrorMessage errors={errors} name='singleErrorInput' /> */}
        </div>
      </form>
    </Layout>
  );
}
