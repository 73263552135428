import { Link } from 'react-router-dom';
import NewsList from './admin/newsList';

export default function NewsSummary({ news }) {
  const contentNum = 5;

  return (
    <div className='flex bg-[#FCFEEF] bg-opacity-60 lg:bg-white lg:rounded-none p-2 lg:p-0 shadow-md lg:shadow-none flex-col min-h-[250px] lg:min-h-[180px] justify-start items-center lg:w-[512px] w-[90%] mx-auto h-full border-b-2 border-gray-200 lg:border-b-0'>
      <div className='mb-2 w-full text-gray-600 relative flex justify-center items-center'>
        <span className='text-2xl border-b-2 border-[#DAE878] w-full pb-2 text-center'>
          오늘의 환경일기
        </span>
        <Link
          to='/list/news'
          className='absolute hover:no-underline duration-200 right-2 text-sm text-slate-500  hover:text-cyan-600 hover:text-[15px] transition-all  ease-in-out cursor-pointer'
          state={{ data: news, title: '오늘의 환경일기' }}
        >
          + 더보기
        </Link>
      </div>

      <ul className='lg:grid lg:grid-rows-4 lg:space-y-0 lg:gap-2 flex flex-col space-y-4 h-full w-full my-0 px-auto '>
        {news.map((news, id) => {
          if (id < contentNum) {
            return NewsList(news);
          }
        })}
      </ul>
    </div>
  );
}
