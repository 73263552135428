import NaverMap from '../../components/map';
import Layout from '../../containers/layoutCotainer';

import { FlagIcon } from '@heroicons/react/24/outline';
import { MapPinIcon } from '@heroicons/react/24/outline';

export default function LocationPage() {
  return (
    <Layout title='찾아오시는 길'>
      <NaverMap />
      <div className='flex flex-col items-start w-full lg:w-[90%] space-y-8 my-16'>
        <h2 className='text-[45px] font-[SamsungOneBold] border-b-2 border-[#F7F8E4]'>
          교 통 안 내
        </h2>
        <div className='w-full flex flex-col lg:flex-none space-y-4 lg:space-x-10'>
          <div className='flex items-center h-fit w-[150px]'>
            <FlagIcon className='-ml-0.5 mr-1.5 h-8 w-8' aria-hidden='true' />
            <span className='text-[30px]'>버 스</span>
          </div>
          <div className='w-full space-y-20 '>
            <div className='flex flex-col space-y-2 lg:pr-8'>
              <div className='flex'>
                <MapPinIcon
                  className='-ml-0.5 mr-1.5 h-6 w-6'
                  aria-hidden='true'
                />
                <span>소 담 동 새 샘 마 을 정 류 장</span>
              </div>
              <div className='border-2 inline-grid w-full h-12 grid-cols-1 lg:grid-cols-2'>
                <div className='border-r-2 w-full h-full flex justify-start items-center p-2 pl-4'>
                  <div className='mr-4 bg-red-400 h-6 w-10 font-[SamsungOne400] rounded-md text-sm flex justify-center items-center text-white'>
                    중앙
                  </div>
                  B0(순환) , B0(터미널), B1 , B5 , B4
                </div>
                <div className=' w-full h-full flex justify-start items-center p-2 pl-4'></div>
              </div>
            </div>
            <div className='flex flex-col space-y-2 lg:pr-8'>
              <div className='flex'>
                <MapPinIcon
                  className='-ml-0.5 mr-1.5 h-6 w-6'
                  aria-hidden='true'
                />
                <span>세 종 우 체 국 (남 부 서 , 보 람 초) 정 류 장</span>
              </div>
              <div className='border-2 inline-grid w-full h-12 grid-cols-1 lg:grid-cols-2'>
                <div className='border-r-2 w-full h-full flex justify-start items-center p-2 pl-4'>
                  <div className='mr-4 bg-green-400 h-6 w-10 font-[SamsungOne400] rounded-md text-sm flex justify-center items-center text-white'>
                    마을
                  </div>
                  221, 222
                </div>
                <div className=' w-full h-full  border-2 lg:border-0  flex justify-start items-center p-2 pl-4'>
                  <div className='mr-4 bg-blue-400 h-6 w-10 font-[SamsungOne400] rounded-md text-sm flex justify-center items-center text-white'>
                    간선
                  </div>
                  601, 991
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
