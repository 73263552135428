import { LOGINKEY } from '../../constant';

const { createContext, useState, useMemo } = require('react');

export const UserValueContext = createContext();
export const UserActionsContext = createContext();

export default function UserProvider({ children }) {
  const [user, setUser] = useState({ token: '' });
  const actions = useMemo(
    () => ({
      login(token) {
        setUser({ token });
      },
      logout() {
        setUser({ token: '' });
        localStorage.removeItem(LOGINKEY);
      },
    }),
    []
  );
  return (
    <UserActionsContext.Provider value={actions}>
      <UserValueContext.Provider value={user}>
        {children}
      </UserValueContext.Provider>
    </UserActionsContext.Provider>
  );
}
