import Layout from '../containers/layoutCotainer';
import MainPicture from '../containers/mainPage/mainPicture';
import MainMenu from '../containers/mainPage/mainMenu';

import useNotice from '../libs/useNotice';

export default function MainPage() {
  useNotice();
  return (
    <Layout>
      <MainPicture />
      <MainMenu />
    </Layout>
  );
}
