import { useEffect, useState } from 'react';
import { noticeApi } from '../api';

export default function useNotice() {
  const [notices, setNotices] = useState([]);

  const checkPopupClose = (key) => {
    const expireDay = localStorage.getItem(key);
    const today = new Date();
    return expireDay && today.getTime() < expireDay;
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await noticeApi.getNotice();
        setNotices(res);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    notices.forEach((notice) => {
      const isClose = checkPopupClose(notice.url);
      if (!isClose) {
        window.open(
          `https://www.ecoepr.com/popup/${notice.id}?title=${notice.title}&key=${notice.url}`,
          notice.id,
          `height=${notice?.height || '600px'}, width=${
            notice?.width || '600px'
          }`
        );
      }
    });
  }, [notices]);
  return useNotice;
}
