import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import Header from '../components/header';
import ScrollTop from '../libs/scrollTop';

export default function Layout({ children, title = 'ePrime' }) {
  return (
    <div className='w-full h-full'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title}</title>
      </Helmet>
      <Header />
      <ScrollTop />
      <div className='flex flex-col w-full min-h-[calc(100vh-128px)] h-fit justify-start items-center'>
        {children}
      </div>
      <Footer />
    </div>
  );
}
