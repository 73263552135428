import LoadingMiniBoard from '../../components/admin/loadingMiniBoard';

export default function LoadingAdminPage() {
  return (
    <div className='grid animate-pulse grid-cols-2 max-w-[1100px] gap-x-2 gap-y-8 w-full my-5 place-items-center place-content-center'>
      <LoadingMiniBoard />
      <LoadingMiniBoard />
      <LoadingMiniBoard />
      <LoadingMiniBoard />
      <LoadingMiniBoard />
      <LoadingMiniBoard />
      <LoadingMiniBoard />
    </div>
  );
}
