import React from 'react';

const Footer = () => {
  return (
    <footer className='footer w-full p-4 flex lg:h-16 min-h-fit flex-col lg:grid lg:grid-cols-3 bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-900 footer-center '>
      <div className='flex justify-center items-center'>
        <span>이프라임 (E-Prime) 대표 장지승</span>
      </div>
      <div className='flex text-center justify-center items-center '>
        <span>
          (우) 30151 세종특별자치시 시청대로 209 금강르네상스 상가 405호
        </span>
      </div>
      <div className='flex justify-center items-center'>
        <span className='no-underline'>Tel: 044-868-3451</span>{' '}
        <span className='pl-6 no-underline'> Fax: 044-868-3458</span>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
