import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Checkbox from '../../components/utils/checkbox';

export default function PopupOne() {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');
  const key = searchParams.get('key');
  const imgRef = useRef();
  const [isClose, setIsClose] = useState(false);

  const closePopup = () => {
    if (isClose) {
      let expire = new Date();
      expire.setTime(expire.getTime() + 24 * 60 * 60 * 1000);
      localStorage.setItem(key, expire.getTime());
    }
    window.close();
  };

  useEffect(() => {
    window.resizeTo(
      imgRef.current.getBoundingClientRect().width,
      imgRef.current.getBoundingClientRect().height
    );
  }, []);

  return (
    <div className={`min-h-fit max-w-fit relative`}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <img
        ref={imgRef}
        src={`https://eprnet.s3.ap-northeast-2.amazonaws.com/${key}`}
        alt='popup'
      />
      <div className='absolute mr-4 flex justify-end bottom-0 left-0 w-full h-6 z-10'>
        <Checkbox checked={isClose} onChange={setIsClose}>
          오늘 하루 열지않기
        </Checkbox>
        <button className='ml-4 mr-4 btn btn-blue' onClick={closePopup}>
          닫기
        </button>
      </div>
    </div>
  );
}
