import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../../../../containers/adminLayoutContainer';
import { useNavigate } from 'react-router-dom';
import { adminApi } from '../../../../adminApi';
import Write from '../../../../components/reactQuill';

export default function AddNews() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const editorContent = watch('content');

  const dateNow = new Date();
  const today = dateNow.toISOString().slice(0, 10);

  // useEffect(() => {
  //   register('content', { required: true });
  // }, [register]);

  const onEditorStateChange = (editorState) => {
    setValue('content', editorState);
  };

  const onSubmit = async (data) => {
    console.log('data', data);
    try {
      const res = await adminApi.addNews(data);
      if (res.data.success) {
        navigate('/admin');
      } else {
        navigate('/addNews');
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Layout>
      <form
        className='flex w-full lg:px-0 lg:w-[65%] my-auto mt-6 flex-col justify-center items-center px-6 '
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='w-full grid gap-x-6 gap-y-8  grid-cols-1 lg:grid-cols-6'>
          <div className='lg:col-span-4'>
            <label
              htmlFor='title'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Title
            </label>
            <div className='mt-2'>
              <input
                id='title'
                {...register('title')}
                type='title'
                autoComplete='title'
                required
                className='block w-full rounded-md border-0 p-1.5 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6'
              />
            </div>
          </div>

          <div className='lg:col-span-2'>
            <label
              htmlFor='date'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Date
            </label>
            <div className='mt-2'>
              <input
                id='date'
                {...register('date')}
                defaultValue={today}
                type='date'
                autoComplete='date'
                required
                className='block w-full m-0 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='lg:col-span-6 mt-6'>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='content'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Content
              </label>
            </div>
            {/* <div className='mt-2'>
              <textarea
                id='content'
                {...register('content')}
                type='content'
                autoComplete='content'
                required
                className='block w-full rounded-md border-0 p-1.5 '
              />
            </div> */}
            <Write value={editorContent} onChange={onEditorStateChange} />
          </div>

          <div className='flex justify-end col-span-full my-6'>
            <button
              type='submit'
              className='flex w-[20%] min-w-fit justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600'
            >
              추가하기
            </button>
          </div>

          {/* <ErrorMessage errors={errors} name='singleErrorInput' /> */}
        </div>
      </form>
    </Layout>
  );
}
