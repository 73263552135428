import { useState, useContext } from 'react';

import { UserValueContext, UserActionsContext } from './userContext';

export function useUserValue() {
  const value = useContext(UserValueContext);

  if (value === undefined) {
    throw new Error('useUserState should be used within UserProvider');
  }
  return value;
}

export function useUserActions() {
  const value = useContext(UserActionsContext);
  if (value === undefined) {
    throw Error('useUserState should be used within UserProvider');
  }
  return value;
}
