import axios from 'axios';
import { BASEURL, LOGINKEY } from './constant';

const api = axios.create({
  baseURL: BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem(LOGINKEY);
  try {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.error('[_axios.interceptors.request] config:' + error);
  }
  return config;
});

export const adminApi = {
  getUrl: async (data) => {
    return await api.post('api/cms/notice/image', data);
  },
  addNotice: async (data) => {
    return await api.post('api/cms/notice', data);
  },
  addPopup: async (data) => {
    return await api.post('api/cms/notice', data);
  },
  addNews: async (data) => {
    return await api.post('api/cms/news', data);
  },
  deleteNews: async (id) => {
    return await api.delete(`api/cms/news/${id}`);
  },
  deleteItem: async (id, type) => {
    return await api.delete(`api/cms/record/${type}/${id}`);
  },
  deleteNotice: async (id) => {
    return await api.delete(`api/cms/notice/${id}`);
  },
  getNotice: async () => {
    const res = await api.get('api/cms/notice');
    if (res.data.result) {
      res.data.result.reverse();
    }
    return res.data.result;
  },
  getNews: async () => {
    const res = await api.get('api/cms/news');
    if (res.data.result) {
      res.data.result.reverse();
    }
    return res.data.result;
  },
  getEsg: async () => {
    const res = await api.get('api/cms/record?recordType=esg');

    if (res.data.result) {
      res.data.result.reverse();
    }
    return res.data.result;
  },
  getSf: async () => {
    const res = await api.get('api/cms/record?recordType=smartFactory');
    if (res.data.result) {
      res.data.result.reverse();
    }
    return res.data.result;
  },
  getEtc: async () => {
    const res = await api.get('api/cms/record?recordType=etc');
    if (res.data.rseult) {
      res.data.result.reverse();
    }
    return res.data?.result;
  },
  getMm: async () => {
    const res = await api.get('api/cms/record?recordType=makeMaterials');
    if (res.data.result) {
      res.data.result.reverse();
    }
    return res.data?.result;
  },
  getMp: async () => {
    const res = await api.get('api/cms/record?recordType=makeProduct');
    if (res.data.rseult) {
      res.data.result.reverse();
    }
    return res.data?.result;
  },
  getWs: async () => {
    const res = await api.get('api/cms/record?recordType=wholeSale');
    if (res.data.result) {
      res.data.result.reverse();
    }
    return res.data?.result;
  },
};
