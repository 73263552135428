import { useEffect, useState } from 'react';
import Layout from '../../containers/adminLayoutContainer';
import MiniBoard from '../../components/admin/miniBoard';
import { adminApi } from '../../adminApi';
import LoadingAdminPage from './LoadingAdminPage';

export default function AdminPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    newsData: [],
    esgData: [],
    etcData: [],
    sfData: [],
    mmData: [],
    mpData: [],
    wsData: [],
    noticeData: [],
  });

  async function getData() {
    const newsData = await adminApi.getNews();
    const esgData = await adminApi.getEsg();
    const etcData = await adminApi.getEtc();
    const sfData = await adminApi.getSf();
    const mmData = await adminApi.getMm();
    const mpData = await adminApi.getMp();
    const wsData = await adminApi.getWs();
    const noticeData = await adminApi.getNotice();
    setData({
      newsData,
      esgData,
      etcData,
      sfData,
      mmData,
      mpData,
      wsData,
      noticeData,
    });
  }

  useEffect(() => {
    try {
      setIsLoading(true);
      getData();
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Layout title='EPRIME ADMIN'>
      {!isLoading ? (
        <div className='grid grid-cols-1 lg:grid-cols-2 lg:max-w-[1100px]  w-full gap-x-2 gap-y-8 mx-auto my-5 place-items-center place-content-center'>
          <MiniBoard
            title='오늘의 환경일기'
            addLink='/addNews'
            plusLink='/list/news'
            items={data.newsData}
            url='/showNews/'
            type='news'
          />
          <MiniBoard
            title='선별 - 원료'
            plusLink='/list/pickList'
            items={data.mmData}
            url='/amdin/form_pick/'
            type='makeMaterials'
          />
          <MiniBoard
            title='원료 - 제품생산'
            plusLink='/list/produce'
            items={data.mpData}
            url='/admin/form_produce/'
            type='makeProduct'
          />
          <MiniBoard
            title='제품 - 도매상'
            plusLink='/list/productList'
            items={data.wsData}
            url='/admin/form_product/'
            type='wholeSale'
          />
          <MiniBoard
            title='ESG 경영'
            plusLink='/list/esgList'
            items={data.esgData}
            url='/admin/form_esg/'
            type='esg'
          />
          <MiniBoard
            title='스마트공장 지원 신청'
            plusLink='/list/smartFactories'
            items={data.sfData}
            url='/admin/form_smrtFactory/'
            type='smartFactory'
          />
          <MiniBoard
            title='기타 도움이 필요한 사항'
            plusLink='/list/etcList'
            items={data.etcData}
            url='/admin/form_etc/'
            type='etc'
          />
          <MiniBoard
            title='팝 업 창'
            addLink='/addPopup'
            items={data.noticeData}
            url='https://www.ecoepr.com/popup/'
            type='notice'
          />
        </div>
      ) : (
        <LoadingAdminPage />
      )}
    </Layout>
  );
}
