import { useRef } from 'react';
import ReactQuill from 'react-quill';

export default function Write({ value, onChange }) {
  const quillRef = useRef(null);

  const toolbarOptions = [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ];
  const formats = [
    'font',
    'size',
    'header',
    'color',
    'background',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  const modules = {
    toolbar: { container: toolbarOptions },
  };

  return (
    <>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        style={{ width: '100%', height: '450px' }}
        modules={modules}
        formats={formats}
        placeholder='내용을 입력하세요.'
      />
    </>
  );
}
