import { Link, useNavigate } from 'react-router-dom';
import { adminApi } from '../../adminApi';

export default function List({ title, id, url = '/', data, type }) {
  const navigate = useNavigate();
  const handleClick = async () => {
    if (type === 'news') {
      try {
        await adminApi.deleteNews(id);
      } catch (error) {
        alert(error);
      } finally {
        navigate('/admin');
        window.location.reload();
      }
    } else if (type === 'notice') {
      try {
        await adminApi.deleteNotice(id);
      } catch (error) {
        alert(error);
      } finally {
        navigate('/admin');
        window.location.reload();
      }
    } else {
      try {
        await adminApi.deleteItem(id, type);
      } catch (error) {
        alert(error);
      } finally {
        navigate('/admin');
        window.location.reload();
      }
    }
  };

  const handleLinkClick = () => {
    console.log(data);
    window.open(
      `${url}?title=${data.title}&key=${data.url}`,
      data.id,
      `height=${data?.height || '600px'}, width=${data?.width || '600px'}`
    );
  };
  return (
    <li className='flex w-full justify-between' key={id}>
      <span>{title}</span>
      <div className='space-x-2'>
        {type === 'notice' ? (
          <button
            className='hover:no-underline text-blue-500'
            onClick={handleLinkClick}
            state={data}
          >
            바로가기
          </button>
        ) : (
          <Link className='hover:no-underline' to={url} state={data}>
            바로가기
          </Link>
        )}
        <button
          className='text-red-400 hover:text-red-600'
          onClick={handleClick}
        >
          삭제
        </button>
      </div>
    </li>
  );
}
