import DOMPurify from 'dompurify';
import Layout from '../../../../containers/layoutCotainer';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export default function ShowNews() {
  const location = useLocation();
  const [data, setData] = useState();

  useEffect(() => {
    console.log(location);
    if (location?.state) {
      setData({ ...location.state });
    }
  }, [location]);

  return (
    <Layout>
      {data?.content && (
        <div className='lg:w-[70%] w-full px-4 mt-6'>
          <div className='flex lg:flex-none flex-col justify-between align-bottom border-b-2 mb-6'>
            <p className='lg:text-[60px] text-[28px]'>{data.title}</p>
            <p className=' m-0 w-full text-right flex justify-end'>
              {data.date}
            </p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(String(data?.content)),
            }}
          />
        </div>
      )}
    </Layout>
  );
}
