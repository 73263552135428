import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Item({ title, data, token }) {
  const [userUrl, setUserUrl] = useState('/');

  useEffect(() => {
    switch (title) {
      case '오늘의 환경일기':
        setUserUrl(`/showNews/${data.id}`);
        break;
      case '선별 - 원료':
        setUserUrl(`/admin/form_product/${data.id}`);
        break;
      case '원료 - 제품생산':
        setUserUrl(`/admin/form_produce/${data.id}`);
        break;
      case '제품 - 도매상':
        setUserUrl(`/admin/form_pick/${data.id}`);
        break;
      case 'ESG 경영':
        setUserUrl(`/admin/form_esg/${data.id}`);
        break;
      case '스마트공장 지원 신청':
        setUserUrl(`/admin/form_smartfactory/${data.id}`);
        break;
      case '기타 도움이 필요한 사항':
        setUserUrl(`/admin/form_etc/${data.id}`);
        break;
      default:
        break;
    }
  }, [userUrl, data.id, data.url, title]);
  return (
    <li
      className='flex h-12 px-4 w-full justify-between items-center border-b-2 bodrer-cyan-700'
      key={data.id}
    >
      <Link
        className='hover:no-underline text-gray-900 hover:text-gray-500 w-full'
        to={userUrl}
        state={data}
      >
        {data.title || data.company}
      </Link>
      {token && (
        <div className=' space-x-2 flex justify-end w-[140px]'>
          <Link className='hover:no-underline' to={userUrl} state={data}>
            바로가기
          </Link>
          <button className='text-red-400 hover:text-red-600'>삭제</button>
        </div>
      )}
    </li>
  );
}
