import { useEffect, useRef } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { PhoneIcon, PrinterIcon } from '@heroicons/react/24/solid';
export default function NaverMap() {
  const mapElement = useRef(null);
  const { naver } = window;

  useEffect(() => {
    if (!mapElement.current || !naver) return;

    const location = new naver.maps.LatLng(36.4834227, 127.2933198);

    const mapOptions = {
      center: location,
      zoom: 16,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };

    const map = new naver.maps.Map(mapElement.current, mapOptions);

    new naver.maps.Marker({
      position: location,
      map: map,
    });
  }, []);

  return (
    <div ref={mapElement} className='mt-4 w-full lg:w-[90%] h-[400px]'>
      <div className='absolute hidden lg:flex b-[400px] z-10 p-2 w-full justify-around bg-[#F7F8E4] bg-opacity-80'>
        <div className='flex'>
          <MapPinIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
          <span>주소 : 세종특별자치시 시청대로 209 금강르네상스 405호</span>
        </div>
        <div className='flex'>
          <PhoneIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
          <span>044-868-3451</span>
        </div>
        <div className='flex'>
          <PrinterIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
          <span>044-868-3458</span>
        </div>
      </div>
    </div>
  );
}
