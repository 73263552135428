import MainImageJpeg from '../../assets/img/mainImage4.jpg';
import ManImageWebp from '../../assets/img/mainImage4.webp';
import SubImageJpeg from '../../assets/img/mainImage3.jpeg';
import SubImageWebp from '../../assets/img/mainImage3.webp';

export default function MainPicture() {
  return (
    <>
      <div className='hidden lg:block w-full h-fit relative '>
        <picture className='object-cover'>
          <source srcSet={ManImageWebp} type='image/webp' />
          <img src={MainImageJpeg} alt='mainImage' />
        </picture>
      </div>
      <div className='lg:hidden w-full h-fit relative'>
        <picture className='object-cover'>
          <source srcSet={SubImageWebp} type='image/webp' />
          <img src={SubImageJpeg} alt='mainImage' />
        </picture>
      </div>
    </>
  );
}
