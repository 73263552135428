import { useEffect } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import ScrollTop from '../libs/scrollTop';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useUserActions, useUserValue } from '../hooks/context/useUserState';
import { LOGINKEY } from '../constant';

export default function Layout({ children, title }) {
  const navigate = useNavigate();
  const user = useUserValue();
  const { login } = useUserActions();

  useEffect(() => {
    if (!user.token) {
      const token = localStorage.getItem(LOGINKEY);
      if (token) {
        login(token);
      } else {
        navigate('/login');
      }
    }
  }, [navigate, user, login]);

  return (
    <div className='w-full h-full'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title}</title>
      </Helmet>
      <Header />
      <ScrollTop />
      <div className='flex flex-col w-full min-h-[calc(100vh-128px)] h-fit justify-start items-center'>
        {children}
      </div>
      <Footer />
    </div>
  );
}
