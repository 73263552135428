import { useState } from 'react';
import { BriefcaseIcon, LinkIcon, PencilIcon } from '@heroicons/react/20/solid';
import { ReactComponent as Logo } from '../assets/img/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useScrollDirection } from '../libs/useScrollDirection';
import { Dialog, Disclosure, Popover } from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  SquaresPlusIcon,
  XMarkIcon,
  DocumentIcon,
  BookOpenIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import PopOver from './popover';
import { useUserActions, useUserValue } from '../hooks/context/useUserState';

export default function Header() {
  const navigate = useNavigate();
  const user = useUserValue();
  const { logout } = useUserActions();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const scrollDirection = useScrollDirection();

  const products = [
    {
      name: 'ESG 원료 지원',
      description: 'ESG 경영 전략 수립시 양질의 원료가 필요하신 분',
      href: '/form_esg',
      icon: ArrowPathIcon,
    },
    {
      name: '폐자원 거래 신청서 ',
      description: '선별 <-> 재활용원료 제조단계 ',
      href: '/form_product',
      icon: CursorArrowRaysIcon,
    },
    {
      name: '폐자원 거래 신청서 ',
      description: '재활용 원료 <-> 재활용 제품 생산 단계',
      href: '/form_produce',
      icon: CursorArrowRaysIcon,
    },
    {
      name: '폐자원 거래 신청서 ',
      description: '재활용 제품 <-> 도매상',
      href: '/form_pick',
      icon: CursorArrowRaysIcon,
    },
    {
      name: '스마트 생태공장 구축 ',
      description: '24 스마트생태공장구축 지원사업 신청하시고자 하는 분',
      href: '/form_factory',
      icon: ChartPieIcon,
    },
    {
      name: '기타 문의사항',
      description: '기타 재활용 사업수행과 관련한 질의나 도움이 필요한 분',
      href: '/form_etc',
      icon: SquaresPlusIcon,
    },
  ];
  const introduce = [
    {
      name: '대표이사 인사말',
      href: '/company/greetings',
      icon: DocumentIcon,
    },
    {
      name: '이프라임(주) 소개',
      href: '/company/introduce',
      icon: BookOpenIcon,
    },
    {
      name: '찾아오시는 길',
      href: '/company/location',
      icon: MapPinIcon,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const handleUser = () => {
    if (user.token) {
      logout();
      window.location.reload();
    } else {
      navigate('/login');
    }
  };

  return (
    <div
      className={`z-20 bg-white transition-all duration-500 sticky ${
        scrollDirection === 'down' ? '-top-16' : 'top-0'
      } header w-full flex justify-center h-16 lg:min-w-[1024px] shadow-md `}
    >
      <div className='lg:px-30 flex items-center justify-between w-[90%] h-full'>
        <Link to='/'>
          <Logo className='text-[15px]' />
          <h1 className='text-xl sr-only'>Eprime</h1>
        </Link>
        <div className='flex lg:hidden mr-2'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <Popover.Group className='hidden w-full lg:flex lg:justify-around lg:gap-x-12'>
          <PopOver products={introduce}>
            <span className='leading-6'>
              <button
                type='button'
                className='inline-flex items-center px-8 py-2 text-md text-gray-900 border-b-2  hover:border-sky-800 hover:text-gray-500'
              >
                <LinkIcon
                  className='-ml-0.5 mr-1.5 h-5 w-5'
                  aria-hidden='true'
                />
                회사소개
              </button>
            </span>
          </PopOver>
          <PopOver products={products}>
            <span className='leading-6'>
              <button
                type='button'
                className='inline-flex items-center  px-8 py-2 text-md text-gray-900 border-b-2  hover:border-sky-800 hover:text-gray-500'
              >
                <BriefcaseIcon
                  className='-ml-0.5 mr-1.5 h-5 w-5'
                  aria-hidden='true'
                />
                폐자원거래
              </button>
            </span>
          </PopOver>

          <span className='leading-6'>
            <button
              type='button'
              className='inline-flex items-center px-8 py-2 text-md text-gray-900 border-b-2 hover:border-sky-800 hover:text-gray-500'
            >
              <PencilIcon
                className='-ml-0.5 mr-1.5 h-5 w-5'
                aria-hidden='true'
              />
              소통마당
            </button>
          </span>
        </Popover.Group>
        <div className='hidden relative cursor-pointer group lg:flex lg:flex-1 lg:justify-end'>
          {user.token ? (
            <>
              <span className='sm:ml-6 w-[70px] text-red-400 hover:font-[SamsungOneBold] underline-offset-2'>
                관리자
              </span>
              <div className='hidden hover:flex group-hover:flex absolute -left-8 top-full z-10 pt-3  overflow-hidden rounded-xl shadow-lg w-screen max-w-[180px] flex-col p-3 bg-white ring-1 ring-gray-900/5'>
                <div className='flex items-center rounded-lg p-4 text-sm leading-6 hover:bg-gray-50'>
                  <Link
                    className='w-full h-full text-black no-underline hover:no-underline'
                    to='/admin'
                  >
                    ADMIN PAGE
                  </Link>
                </div>
                <div className='flex items-center rounded-lg p-4 text-sm leading-6 hover:bg-gray-50'>
                  <Link
                    className='w-full h-full text-black no-underline hover:no-underline'
                    to='/admin'
                  >
                    비밀번호 변경
                  </Link>
                </div>
                <div
                  onClick={handleUser}
                  type='button'
                  className='flex items-center rounded-lg p-4 text-sm leading-6 hover:bg-gray-50'
                >
                  로그아웃
                </div>
              </div>
            </>
          ) : (
            <div
              onClick={handleUser}
              type='button'
              className='flex w-[70px] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-gray-500'
            >
              <span className='w-full text-center'>로그인</span>
            </div>
          )}
        </div>
      </div>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='z-50 fixed inset-y-0 right-0 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <Link to='/' className='-m-1.5'>
              <span className='sr-only'>Your Company</span>
              <Logo className='h-8' />
            </Link>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                <Disclosure as='div' className='-mx-3'>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='outline-none flex w-full items-center justify-between rounded-lg py-2 pl-1 pr-3.5 text-base leading-7 text-gray-900 hover:bg-gray-50'>
                        <span className='sm:ml-3 '>
                          <button
                            type='button'
                            className='inline-flex items-center px-8 py-2 text-md text-gray-900  hover:text-gray-500'
                          >
                            <LinkIcon
                              className='-ml-0.5 mr-1.5 h-5 w-5'
                              aria-hidden='true'
                            />
                            회사소개
                          </button>
                        </span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'rotate-180' : '',
                            'h-5 w-5 flex-none'
                          )}
                          aria-hidden='true'
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className='mt-2 space-y-2'>
                        {[...introduce].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as='a'
                            href={item.href}
                            className='block hover:no-underline hover:text-gray-400 rounded-lg py-2 pl-6 pr-3 text-sm leading-7 text-gray-900 hover:bg-gray-50'
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                <Disclosure as='div' className='-mx-3'>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='outline-none flex w-full items-center justify-between rounded-lg py-2 pl-1 pr-3.5 text-base leading-7 text-gray-900 hover:bg-gray-50'>
                        <span className='sm:ml-3 '>
                          <button
                            type='button'
                            className='inline-flex items-center px-8 py-2 text-md text-gray-900  hover:border-sky-800 hover:text-gray-500'
                          >
                            <BriefcaseIcon
                              className='-ml-0.5 mr-1.5 h-5 w-5'
                              aria-hidden='true'
                            />
                            폐자원거래
                          </button>
                        </span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'rotate-180' : '',
                            'h-5 w-5 flex-none'
                          )}
                          aria-hidden='true'
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className='mt-2 space-y-2'>
                        {[...products].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as='a'
                            href={item.href}
                            className='flex flex-col group rounded-lg py-2 pl-6 pr-3 text-sm leading-7 text-gray-900 hover:bg-gray-50 hover:text-gray-400 hover:no-underline'
                          >
                            {item.name}
                            <span className='text-xs text-gray-400 group-hover:text-gray-300'>
                              {item.description}
                            </span>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <span className='-mx-3 block rounded-lg px-3 py-2 text-baseleading-7 text-gray-900 hover:bg-gray-50'>
                  <button
                    type='button'
                    className='inline-flex items-center w-full px-8 py-2 text-md text-gray-900 hover:text-gray-500'
                  >
                    <PencilIcon
                      className='-ml-0.5 mr-1.5 h-5 w-5'
                      aria-hidden='true'
                    />
                    소통마당
                  </button>
                </span>
              </div>
              <div className='py-6'>
                <div className=' py-2.5 -mx-3 block rounded-lg px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                  <button
                    onClick={handleUser}
                    className='inline-flex w-full cursor-pointer outline-none items-center rounded-md px-3 py-2 text-sm font-semibold'
                  >
                    {user.token ? 'LogOut' : 'LogIn'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}
