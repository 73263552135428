import Layout from '../../containers/layoutCotainer';
import IntroduceImageWebp from '../../assets/img/introduceImage.webp';
import IntroduceImageJpg from '../../assets/img/introduceImage.jpeg';

export default function IntroducePage() {
  return (
    <Layout title='이프라임(주) 소개'>
      <div className='w-[90%]'>
        <picture className='w-ful h-fit'>
          <source srcSet={IntroduceImageWebp} type='image/webp' />
          <img src={IntroduceImageJpg} alt='greetingImage' />
        </picture>
      </div>
    </Layout>
  );
}
