import { Link } from 'react-router-dom';

export default function NewsList({ title, id, content, date }) {
  return (
    <li className='flex truncate justify-between items-center' key={id}>
      <Link
        rel='noopener noreferrer'
        className='text-black hover:no-underline truncate'
        to={`/showNews/${id}`}
        state={{ title, id, content, date }}
      >
        {title}
      </Link>

      <span className='text-sm flex justify-end w-[125px]'>{date}</span>
    </li>
  );
}
