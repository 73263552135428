import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

export default function PopupMenus() {
  const [isVisible, setVisible] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', clickMenuOutside);
    return () => {
      document.removeEventListener('mousedown', clickMenuOutside);
    };
  });
  const clickMenuOutside = (e) => {
    if (isVisible && !menuRef.current.contains(e.target)) {
      handleClick();
    }
  };
  const handleClick = () => {
    setVisible(!isVisible);
  };

  return (
    <div className='flex flex-col mt-6 lg:mt-0 justify-center items-center w-[90%] mx-auto lg:w-[512px] h-full'>
      <ul className='lg:grid lg:grid-rows-4 flex flex-col space-y-4 lg:space-y-0 w-full h-full my-0 px-auto lg:gap-1'>
        <li className='flex justify-between items-center truncate'>
          <span className='text-lg truncate'>
            ESG 경영 전략 수립시 양질의 원료가 필요하신 분!
          </span>
          <Link
            to='/form_esg'
            className='text-lg font-bold text-gray-500 hover:no-underline hover:text-cyan-600 hover:transform-gpu hover:drop-shadow-xl transition-all ease-in-out duration-200'
          >
            Click
          </Link>
        </li>
        <li className='group hidden lg:flex justify-between items-center text-lg relative '>
          <span className='text-lg truncate'>
            폐자원(Pellet, Flake 등)사고파는데 도움이 필요하신 분!
          </span>
          <div className='peer font-bold text-gray-500 hover:text-cyan-600 hover:drop-shadow-xl hover:font-extrabold transition-all ease-in-out duration-200 cursor-pointer'>
            <button className='text-lg' onClick={handleClick}>
              Click
            </button>
          </div>
          <ul
            ref={menuRef}
            className={`lg:absolute ${
              isVisible ? 'block' : 'hidden'
            } text-lg z-10 left-full top-[calc(-65%)] w-full text-red-500 overflow-hidden rounded-xl shadow-md flex flex-col p-3 bg-white ring-1 ring-gray-900/5`}
          >
            <li className='p-1'>
              <Link
                to='/form_product'
                className=' text-gray-500 hover:no-underline bg-white rounded-lg hover:text-cyan-600 transition-all ease-in-out duration-200'
              >
                폐자원 거래 신청서(선별 &lt;-&gt; 재활용원료 제조 단계)
              </Link>
            </li>
            <li className='p-1'>
              <Link
                to='/form_produce'
                className=' text-gray-500 hover:no-underline bg-white rounded-lg hover:text-cyan-600 transition-all ease-in-out duration-200'
              >
                폐자원 거래 신청서(재활용 원료 &lt;-&gt; 재활용제품 생산 단계)
              </Link>
            </li>
            <li className='p-1'>
              <Link
                to='/form_pick'
                className=' text-gray-500 hover:no-underline bg-white rounded-lg hover:text-cyan-600 transition-all ease-in-out duration-200'
              >
                폐자원 거래 신청서(재활용 제품 &lt;-&gt; 도매상)
              </Link>
            </li>
          </ul>
        </li>
        <li className='flex justify-between items-center truncate lg:hidden'>
          <span className='text-lg truncate'>
            폐자원 거래 신청서(선별 &lt;-&gt; 재활용원료 제조단계)
          </span>
          <Link
            to='/form_product'
            className='text-lg font-bold text-gray-500 hover:no-underline hover:text-cyan-600 hover:transform-gpu hover:drop-shadow-xl transition-all ease-in-out duration-200'
          >
            Click
          </Link>
        </li>
        <li className='flex justify-between items-center truncate lg:hidden'>
          <span className='text-lg truncate'>
            폐자원 거래 신청서(재활용 원료 &lt;-&gt; 재활용제품 생산단계)
          </span>
          <Link
            to='/form_produce'
            className='text-lg font-bold text-gray-500 hover:no-underline hover:text-cyan-600 hover:transform-gpu hover:drop-shadow-xl transition-all ease-in-out duration-200'
          >
            Click
          </Link>
        </li>
        <li className='flex justify-between items-center truncate lg:hidden'>
          <span className='text-lg truncate'>
            폐자원 거래 신청서(재활용 제품 &lt;-&gt; 도매상)
          </span>
          <Link
            to='/form_pick'
            className='text-lg font-bold text-gray-500 hover:no-underline hover:text-cyan-600 hover:transform-gpu hover:drop-shadow-xl transition-all ease-in-out duration-200'
          >
            Click
          </Link>
        </li>
        <li className='flex justify-between items-center truncate'>
          <span className='text-lg truncate'>
            24 스마트생태공장구축 지원사업 신청하시고자 하는분!
          </span>
          <Link
            to='/form_factory'
            className='text-lg font-bold text-gray-500 hover:no-underline hover:text-cyan-600  hover:transform-gpu hover:drop-shadow-xl transition-all ease-in-out duration-200'
          >
            Click
          </Link>
        </li>
        <li className='flex justify-between items-center truncate'>
          <span className='text-lg truncate'>
            기타 재활용사업 수행과 관련하여 질의나 도움이 필요한 분!
          </span>
          <Link
            to='/form_etc'
            className='text-lg font-bold text-gray-500 hover:no-underline hover:text-cyan-600  hover:transform-gpu hover:drop-shadow-xl transition-all ease-in-out duration-200'
          >
            Click
          </Link>
        </li>
      </ul>
    </div>
  );
}
