export default function Checkbox({
  children,
  disabled = false,
  checked,
  onChange,
}) {
  return (
    <label>
      <input
        type='checkbox'
        disabled={disabled}
        checked={checked}
        onChange={({ target: { checked } }) => onChange(checked)}
      />
      {children}
    </label>
  );
}
