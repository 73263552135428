import React, { useEffect } from 'react';
import Logo from '../assets/img/logo.svg';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { signApi } from '../api';
import { Helmet } from 'react-helmet';

import { useUserActions, useUserValue } from '../hooks/context/useUserState';
import { LOGINKEY } from '../constant';

export default function LoginPage() {
  const navigate = useNavigate();
  const user = useUserValue();
  const { login } = useUserActions();

  const { register, handleSubmit } = useForm();

  const handleClick = () => {
    navigate('/');
  };
  const onSubmit = async (data) => {
    try {
      const res = await signApi.signIn(data);
      if (res.data.login) {
        localStorage.setItem(LOGINKEY, res.data.token);
        login(res.data.token);
      } else {
        throw Error('비밀번호를 확인 해주세요');
      }
    } catch (error) {
      alert(error);
    } finally {
      navigate('/admin');
    }
  };

  useEffect(() => {
    if (user.token) {
      navigate('/admin');
    }
  }, [user, navigate]);

  return (
    <>
      <Helmet>
        <title>LogIn</title>
      </Helmet>
      <div className='flex min-h-full flex-1 flex-col justify-center my-auto px-6 lg:px-8'>
        <div
          className='sm:mx-auto sm:w-full sm:max-w-md cursor-pointer'
          onClick={handleClick}
        >
          <img className='mx-auto h-16 w-auto' src={Logo} alt='Your Company' />
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
          <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Email
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  {...register('email')}
                  type='email'
                  autoComplete='email'
                  required
                  className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Password
                </label>
              </div>
              <div className='mt-2'>
                <input
                  id='password'
                  {...register('password')}
                  type='password'
                  autoComplete='password'
                  required
                  className='block w-full font-serif rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='space-y-2'>
              <button
                type='submit'
                className='flex w-full justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600'
              >
                로그인
              </button>
              {/* <div className='flex w-full justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600'>
                <Link
                  to='/signin'
                  className='w-full h-full text-center text-white hover:no-underline hover:text-white'
                >
                  회원가입
                </Link>
              </div> */}
              {/* <ErrorMessage errors={errors} name='singleErrorInput' /> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
