import { useCallback, useEffect, useState } from 'react';

import { newsApi } from '../../api';
import PopupMenus from '../../components/popupMenus';
import NewsSummary from '../../components/newsSummary';

export default function MainMenu() {
  const [news, setNews] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const news = await newsApi.getAll();
      if (news.data.result.length) {
        news.data.result.reverse();
        setNews(news.data.result);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='w-full h-full flex flex-col-reverse lg:min-w-[1024px] lg:grid lg:grid-cols-2 place-items-center my-10 lg:my-4 lg:space-x-2'>
      <PopupMenus />
      <NewsSummary news={news} />
    </div>
  );
}
